import { CenteredViewProps } from '@/components/CenteredView/CenteredView.types';
import { SecondaryHeader } from '@/components/Headers/SecondaryHeader/SecondaryHeader';

import * as S from './CenteredView.styles';

export const CenteredView: React.FC<CenteredViewProps> = ({
  children,
  withCloseBtn = false
}) => {
  return (
    <S.Wrapper>
      <S.Container>
        <SecondaryHeader withCloseBtn={withCloseBtn} />
        {children}
      </S.Container>
    </S.Wrapper>
  );
};
