import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  palette: {
    brand: {
      main: '#F8DA5A'
    },
    textAndLabels: {
      main: '#2F2F2F',
      secondary: '#555555',
      tertiary: '#989898',
      quaternary: '#C69C00'
    },
    bg: {
      main: '#FCFCFC',
      secondary: '#F6F7FA',
      tertiary: '#FFEEA5',
      card: '#F7F7F7'
    },
    system: {
      deepBlue: '#4264FD',
      blue: '#4C88F9',
      lightBlue: '#DAE8FF',
      green: '#3DB460',
      red: '#FF586D',
      yellow: '#FFB900',
      white: '#FFFFFF',
      black: '#1A1A1A'
    },
    border: {
      main: '#DBDBDB',
      secondary: '#EAEBF2',
      tertiary: '#DFE3E9'
    }
  }
};
