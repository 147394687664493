'use client';

import { useTranslation } from 'react-i18next';

import { CenteredView } from '@/components/CenteredView/CenteredView';
import { ErrorPageIcon } from '@/components/ui/Icons/Icons';
import { Typography } from '@/components/ui/Typography/Typography.styles';
import { useMediaQuery } from '@/hooks/useMediaQuery';

import * as S from './Error.styles';

interface ErrorProps {
  reset?: () => void;
}

export const Error: React.FC<ErrorProps> = ({ reset }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('mobile');

  const handleReload = () => {
    if (reset) {
      reset();
    } else {
      window.location.reload();
    }
  };

  return (
    <CenteredView>
      <S.Content>
        <ErrorPageIcon />

        <S.Texts>
          <Typography variant={isMobile ? 'h1' : 'h0'}>
            {t('Error.title')}
          </Typography>
          <Typography variant={isMobile ? 'body3' : 'body2'}>
            {t('Error.subtitle')}
          </Typography>
        </S.Texts>

        <S.ReloadButton fullWidth onClick={handleReload}>
          {t('Error.button_label')}
        </S.ReloadButton>
      </S.Content>
    </CenteredView>
  );
};
