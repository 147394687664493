'use client';

import { Resource } from 'i18next';
import React, { ReactNode } from 'react';

import { StyledComponentsRegistry } from '@/components/StyledComponentsRegistry';
import { ApolloProvider } from '@/providers/ApolloProvider';
import { ErrorBoundary } from '@/providers/ErrorBoundary';
import { LoaderProvider } from '@/providers/LoaderProvider';
import { SnackbarProvider } from '@/providers/SnackbarProvider';
import { ThemeProvider } from '@/providers/ThemeProvider/ThemeProvider';
import { TranslationProvider } from '@/providers/TranslationProvider';
import { GlobalStyles } from '@/styles/globalStyles';

type ProvidersProps = {
  children: ReactNode;
  params: { locale: string; resources: Resource };
};

export const Providers = ({ children, params }: ProvidersProps) => {
  const { locale, resources } = params;

  const cookieDomain = React.useMemo(() => {
    if (typeof window === 'undefined') return undefined;
    const { hostname } = window.location;
    const parts = hostname.split('.');
    return parts.length > 2 ? `.${parts.slice(-2).join('.')}` : hostname;
  }, []);

  return (
    <StyledComponentsRegistry>
      <ThemeProvider cookieDomain={cookieDomain}>
        <GlobalStyles />
        <ErrorBoundary>
          <TranslationProvider locale={locale} resources={resources}>
            <ApolloProvider locale={locale}>
              <SnackbarProvider>
                <LoaderProvider>{children}</LoaderProvider>
              </SnackbarProvider>
            </ApolloProvider>
          </TranslationProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledComponentsRegistry>
  );
};
