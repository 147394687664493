import styled from 'styled-components';

import { Button } from '@/components/ui/Button/Button';
import { BREAKPOINTS } from '@/constants/breakpoints';
import { flexCenterAligned } from '@/styles/common';

export const Content = styled.div`
  ${flexCenterAligned}
  margin: 0 auto;
  max-width: 378px;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  gap: 35px;
  color: var(--textAndLabels-main);

  @media (max-width: ${BREAKPOINTS.mobile}) {
    max-width: initial;
    padding: 20px;
    gap: 25px;

    svg:nth-child(1) {
      width: 193px;
      height: 193px;
    }
  }
`;

export const Texts = styled.div`
  ${flexCenterAligned}
  flex-direction: column;
  gap: 15px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    gap: 30px;
  }
`;

export const ReloadButton = styled(Button)`
  max-width: 300px;
`;
