import styled, { keyframes } from 'styled-components';

import { flexCenterAligned } from '@/styles/common';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Overlay = styled.div`
  ${flexCenterAligned}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 6;
`;

export const Content = styled.div`
  ${flexCenterAligned}
  flex-direction: column;
  color: var(--textAndLabels-main);
  gap: 20px;
  background: var(--bg-main);
  padding: 20px;
  border-radius: 14px;
`;

export const Spinner = styled.div`
  width: 25px;
  height: 25px;
  animation: ${spin} 1s linear infinite;
`;
