'use client';

import { ApolloError, ApolloLink, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache
} from '@apollo/experimental-nextjs-app-support';
import { PropsWithChildren } from 'react';

import { CONFIG } from '@/config';
import { STATUS_CODES } from '@/constants/common';
import { deleteAuthCookie } from '@/utils/helpers/cookies';
import { hostname } from '@/utils/helpers/locales';

const { SUCCESS, AUTHENTICATION_ERROR } = STATUS_CODES;

function makeClient(locale: string) {
  const httpLink = new HttpLink({
    // this needs to be an absolute url, as relative urls cannot be used in SSR
    uri: `https://${hostname(locale, CONFIG.host)}/graphql`,
    fetchOptions: { cache: 'no-store' },
    credentials: 'include'
  });

  const statusMiddleware = new ApolloLink((operation, forward) =>
    forward(operation).map(response => {
      const data = response?.data;
      const isMutation = operation.query.definitions.some(
        def =>
          def.kind === 'OperationDefinition' && def.operation === 'mutation'
      );

      if (data && isMutation) {
        Object.keys(data).forEach(key => {
          const result = data[key];
          if (result?.status && result.status !== SUCCESS) {
            throw new ApolloError({
              graphQLErrors: [
                {
                  message: `Mutation failed with status: ${result.status}`,
                  extensions: {
                    code: result.status,
                    lockExpiresAt: result?.lockExpiresAt
                  }
                }
              ]
            });
          }
        });
      }

      return response;
    })
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      const authError = graphQLErrors.some(
        error => error.extensions?.code === AUTHENTICATION_ERROR
      );

      if (authError) {
        deleteAuthCookie();
      }

      graphQLErrors.forEach(({ extensions, locations, path }) =>
        console.error(
          `[GraphQL error]: Status: ${extensions?.code}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        )
      );
    }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            profile: {
              merge: true
            },
            ads: {
              keyArgs: ['state']
            }
          }
        }
      }
    }),
    link: from([errorLink, statusMiddleware, httpLink])
  });
}

export const ApolloProvider = ({
  children,
  locale
}: PropsWithChildren<{ locale: string }>) => {
  return (
    <ApolloNextAppProvider makeClient={() => makeClient(locale)}>
      {children}
    </ApolloNextAppProvider>
  );
};
