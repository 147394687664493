'use client';

import { createInstance, Resource } from 'i18next';
import { memo, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';

import { initTranslations } from '@/i18n';

interface TranslationProviderProps {
  locale: string;
  resources: Resource;
}

export const TranslationProvider = memo<
  PropsWithChildren<TranslationProviderProps>
>(({ children, locale, resources }) => {
  const i18n = createInstance();

  initTranslations(locale, i18n, resources);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
});

TranslationProvider.displayName = 'TranslationProvider';
