import { NextRequest } from 'next/server';

import { defaultLocale, locales, LocalesEnum } from '@/constants/locales';

const domainLocaleMap: Record<string, LocalesEnum> = {
  'en.': LocalesEnum.EN,
  'ru.': LocalesEnum.RU
};

export const i18nConfig = {
  locales,
  defaultLocale,
  noPrefix: true,
  localeDetector: (request: NextRequest) => {
    const host =
      request.headers.get('x-forwarded-host') ||
      request.headers.get('host') ||
      '';
    return Object.entries(domainLocaleMap).reduce(
      (acc, [domainPrefix, localeEnum]) =>
        host.startsWith(domainPrefix) ? localeEnum : acc,
      defaultLocale as LocalesEnum
    );
  }
};
