'use client';

import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';
import { flexCenterAligned } from '@/styles/common';

export const Wrapper = styled.div`
  height: 100vh;
  padding: 20px;
  gap: 20px;
  background-color: var(--bg-main);

  @media (max-width: ${BREAKPOINTS.tablet}) {
    padding: 10px;
  }
`;

export const Container = styled.div`
  ${flexCenterAligned}
  height: 100%;
  flex-direction: column;
  border-radius: 20px;
  background-color: var(--bg-card);
`;
