import { DefaultTheme } from 'styled-components';

export const darkTheme: DefaultTheme = {
  palette: {
    brand: {
      main: '#F7C843'
    },
    textAndLabels: {
      main: '#FCFCFC',
      secondary: '#D3D3D3',
      tertiary: '#A6A6A6',
      quaternary: '#D4A300'
    },
    bg: {
      main: '#282828',
      secondary: '#343434',
      tertiary: '#FFEEA5',
      card: '#383838'
    },
    system: {
      deepBlue: '#5993FF',
      blue: '#4D6DFF',
      lightBlue: '#E3EEFF',
      green: '#4EBE6F',
      red: '#FF697C',
      yellow: '#FFB900',
      white: '#FDFDFD',
      black: '#1A1A1A'
    },
    border: {
      main: '#696969',
      secondary: '#525252',
      tertiary: '#4A4A4A'
    }
  }
};
