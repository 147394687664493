'use client';

import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/Loader/Loader';

interface LoaderContextProps {
  showLoader: (textKey?: string) => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextProps | undefined>(undefined);

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within LoaderProvider');
  }
  return context;
};

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [textKey, setTextKey] = useState<string>('Loader.loading');

  const { t } = useTranslation();

  const showLoader = (loaderTextKey?: string) => {
    if (loaderTextKey) {
      setTextKey(loaderTextKey);
    }

    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
    setTextKey('Loader.loading');
  };

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {isLoading && <Loader text={t(textKey)} />}
      {children}
    </LoaderContext.Provider>
  );
};
