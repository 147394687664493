import { SpinnerIcon } from '@/components/ui/Icons/Icons';
import { Typography } from '@/components/ui/Typography/Typography.styles';

import * as S from './Loader.styles';

interface LoaderProps {
  text: string;
}

export const Loader: React.FC<LoaderProps> = ({ text }) => {
  return (
    <S.Overlay>
      <S.Content>
        <S.Spinner>
          <SpinnerIcon />
        </S.Spinner>
        <Typography variant="title3">{text}</Typography>
      </S.Content>
    </S.Overlay>
  );
};
