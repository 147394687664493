'use client';

import { createGlobalStyle, DefaultTheme } from 'styled-components';

import { darkTheme } from '@/styles/darkTheme';
import { lightTheme } from '@/styles/lightTheme';

const generateCssVariables = (theme: DefaultTheme) => {
  return Object.entries(theme.palette)
    .map(([section, colors]) =>
      Object.entries(colors)
        .map(([key, value]) => `--${section}-${key}: ${value};`)
        .join('\n')
    )
    .join('\n');
};

const lightThemeCss = generateCssVariables(lightTheme);
const darkThemeCss = generateCssVariables(darkTheme);

export const GlobalStyles = createGlobalStyle`
  :root {
    ${lightThemeCss};
    --custom-yellow: var(--system-yellow);
    --primary-hover: var(--system-black);
    --title-snackbar: var(--textAndLabels-main);
    --switcher-open: var(--textAndLabels-main);
    --delete-button-outline: var(--system-black);
    --delete-button-hover: #E4E2E2;
    --promote-button-text: var(--bg-main);
    --card-chip-border: var(--border-secondary);
    --selected-chip-border: var(--textAndLabels-main);
    --favourite-fill: #fff;
    --favourite-hover-fill: #E4E2E2;
    --header-scrolled-bg: rgba(252, 252, 252, 0.5);
  }

  [data-theme="dark"] {
    ${darkThemeCss};
    --custom-yellow: var(--bg-tertiary);
    --primary-hover: var(--bg-tertiary);
    --title-snackbar: var(--bg-main);
    --switcher-open: var(--bg-tertiary);
    --delete-button-outline: var(--textAndLabels-main);
    --delete-button-hover: #2a2a2a;
    --promote-button-text: var(--textAndLabels-main);
    --card-chip-border: var(--border-main);
    --selected-chip-border: var(--border-secondary);
    --favourite-fill: transparent;
    --favourite-hover-fill: #000;
    --header-scrolled-bg: rgba(40, 40, 40, 0.5);
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: font-url('./fonts/HelveticaNeue.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: font-url('./fonts/HelveticaNeue-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: font-url('./fonts/HelveticaNeue-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-size-adjust: none;
    -webkit-backface-visibility: hidden;
  }

  body, button, input, textarea, select {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.2;
  }

  body {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    background: var(--bg-main);

    &.ios {
      overscroll-behavior: none;
    }
  }

  img {
    max-width: 100%;
    font-style: italic;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
      display: none;
  }

  input[type=file] {
    display: none;
  }
`;
