import 'dayjs/locale/az';
import 'dayjs/locale/ru';

import dayjs from 'dayjs';
import { createInstance, i18n, Resource } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { TIME_FORMAT } from '@/constants/common';

import { i18nConfig } from './i18nConfig';

const { FULL_DATE_TIME, TIME_ONLY } = TIME_FORMAT;

export async function initTranslations(
  locale: string,
  providedInstance?: i18n,
  resources?: Resource
) {
  const i18nInstance = providedInstance || createInstance();

  i18nInstance.use(initReactI18next);

  if (!resources) {
    i18nInstance.use(
      resourcesToBackend(
        (language: string) => import(`../locales/${language}.json`)
      )
    );
  }

  await i18nInstance.init({
    lng: locale,
    resources,
    fallbackLng: i18nConfig.defaultLocale,
    supportedLngs: i18nConfig.locales,
    preload: resources ? [] : i18nConfig.locales,
    interpolation: {
      format: (value, format, lng) => {
        if (format === 'salaryFormat') {
          const { salaryFrom, salaryTo } = value;

          if (!salaryTo || salaryFrom === salaryTo) {
            return i18nInstance.t('Card.salary_per_month', {
              lng,
              salary: salaryFrom
            });
          }

          return `${salaryFrom} - ${salaryTo} ₼`;
        }
        if (format === 'customFormat') {
          dayjs.locale(lng);

          const today = i18nInstance.t('Card.today', { lng });
          const yesterday = i18nInstance.t('Card.yesterday', { lng });

          const date = dayjs(value);
          const now = dayjs();

          if (date.isSame(now, 'day')) {
            return `${today}, ${date.format(TIME_ONLY)}`;
          }

          if (date.isSame(now.subtract(1, 'day'), 'day')) {
            return `${yesterday}, ${date.format(TIME_ONLY)}`;
          }

          if (date.isAfter(now.subtract(7, 'day'))) {
            return date.fromNow();
          }

          return date.format(FULL_DATE_TIME);
        }

        // Default behavior if no specific format is provided
        return value.toString();
      }
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span']
    }
  });

  return {
    i18n: i18nInstance,
    resources: i18nInstance.services.resourceStore.data,
    t: i18nInstance.t
  };
}
